var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.label || "地址" + "：",
            "label-width": _vm.labelWidth,
            prop: "address",
            rules: _vm.rules
          }
        },
        [
          _c("el-input", {
            attrs: { id: "suggestId", maxlength: "160" },
            on: { input: _vm.handleInput },
            model: {
              value: _vm.keyword,
              callback: function($$v) {
                _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "keyword"
            }
          })
        ],
        1
      ),
      _c("div", {
        ref: "map",
        staticClass: "bmap-search",
        style: "height:" + _vm.height + "px"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }